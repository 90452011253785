import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"

const C = styled.div`
  margin: 20px 20%;
  h1 {
    text-align: center;
    margin-bottom: 60px;
  }
`

const Terms = ({ data: { allContentfulTerms } }) => {
  return (
    <Layout>
      <SEO title="Terms and Conditions for Sammons Industrial" />
      <C className="page-container">
        <div>
          <h1>{allContentfulTerms.edges[0].node.pageHeader}</h1>
          {renderRichText(allContentfulTerms.edges[0].node.pageContent, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            },
          })}
        </div>
      </C>
    </Layout>
  )
}

export default Terms
export const pageQuery = graphql`
  {
    allContentfulTerms {
      edges {
        node {
          pageHeader
          pageContent {
            ...ContentfulTermsPageContentFragment
          }
        }
      }
    }
  }

  fragment ContentfulTermsPageContentFragment on ContentfulTermsPageContent {
    raw
  }
`
